<template>
  <div class="video-background">
    <video autoplay muted loop disablepictureinpicture >
      <source src="https://resource.julycloud.cn/video/energeticforest.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {

    }
  },

  methods: {

  },

  created() {

  },

  mounted() {

  }
}
</script>

<style scoped>
  .video-background {
    position: fixed; /* 使视频背景固定 */
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    overflow: hidden; /* 防止内容溢出 */
  }

  .video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 确保视频覆盖整个容器 */
  }
</style>
